<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'driverlist'">
					<v-row class="pui-form-layout">
						<v-col xs12 lg12>
							<pui-field-set :title="$t('driverlist.fieldset.driver')">
								<v-row dense>
									<!-- FAMILYNAME 1-->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`familyname-driverlist`"
											v-model="model.familyname"
											:label="$t('driverlist.familyname')"
											:disabled="formDisabled"
											required
											toplabel
											maxlength="70"
										></pui-text-field>
									</v-col>
									<!-- GIVENNAME 1-->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`givenname-driverlist`"
											v-model="model.givenname"
											:label="$t('driverlist.givenname')"
											:disabled="formDisabled"
											required
											toplabel
											maxlength="70"
										></pui-text-field>
									</v-col>
									<!-- NATIONALITY 1-->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`nationality-driverlist`"
											:attach="`nationality1-driverlist`"
											:label="$t('driverlist.nationality')"
											:disabled="formDisabled"
											required
											toplabel
											clearable
											modelName="mcountries"
											v-model="model"
											reactive
											:itemsToSelect="nationalityItemsToSelect"
											:modelFormMapping="{ countrycode: 'nationality' }"
											:itemValue="['countrycode']"
											:itemText="(item) => `${item.countrycode} - ${item.countrydesc}`"
										></pui-select>
									</v-col>
									<!-- BIRTHDATE 1-->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-date-field
											:id="`birthdate-driverlist`"
											v-model="model.birthdate"
											:label="$t('driverlist.birthdate')"
											:disabled="formDisabled"
											required
											toplabel
											:max="getMaxDate()"
										></pui-date-field>
									</v-col>
								</v-row>
								<v-row dense style="margin-top: -15px">
									<!-- BIRTHPLACE 1-->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`birthplace-driverlist`"
											v-model="model.birthplace"
											:label="$t('driverlist.birthplace')"
											:disabled="formDisabled"
											required
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-col>
									<!-- GENDERCODE 1-->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`gendercode-driverlist`"
											:attach="`gendercode-driverlist`"
											:label="$t('driverlist.gendercode')"
											:disabled="formDisabled"
											required
											toplabel
											clearable
											modelName="mgender"
											v-model="model"
											reactive
											:itemsToSelect="gendercodeItemsToSelect"
											:modelFormMapping="{ gendercode: 'gendercode' }"
											:itemValue="['gendercode']"
											:itemText="(item) => `${item.genderdescription}`"
											:order="{ genderdescription: 'asc' }"
										></pui-select>
									</v-col>
									<!-- IDENTITY DOCUMENT 1-->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`documenttype-driverlist`"
											:attach="`documenttype1-driverlist`"
											:label="$t('driverlist.documenttype')"
											:disabled="formDisabled"
											required
											toplabel
											modelName="mimofalform5typesiddoc"
											v-model="model"
											:itemsToSelect="typesIdDocItemsToSelect"
											:modelFormMapping="{ identdocid: 'identitydocid' }"
											:itemValue="['identdocid']"
											:itemText="(item) => `${item.typedescription}`"
										></pui-select>
									</v-col>
									<!-- DOCUMENT NUMBER 1-->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`documentnumber-driverlist`"
											v-model="model.docnumber"
											:label="$t('driverlist.documentnumber')"
											:disabled="formDisabled"
											required
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-col>
									<!-- ISSUING STATE 1-->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`issuingstate-driverlist`"
											:attach="`issuingstate1-driverlist`"
											:label="$t('driverlist.issuingstate')"
											:disabled="formDisabled"
											required
											toplabel
											clearable
											modelName="mcountries"
											v-model="model"
											reactive
											:itemsToSelect="issuingstateItemsToSelect"
											:modelFormMapping="{ countrycode: 'issuingstate' }"
											:itemValue="['countrycode']"
											:itemText="(item) => `${item.countrycode} - ${item.countrydesc}`"
										></pui-select>
									</v-col>
									<!-- EXPIRY DATE 1-->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-date-field
											:id="`expirydate1 -driverlist`"
											v-model="model.expirydate"
											:label="$t('driverlist.expirydate')"
											:disabled="formDisabled"
											required
											toplabel
											maxlength="35"
										></pui-date-field>
									</v-col>
									<!-- LICENCE CATEGORY 1-->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`licencecategory1 -driverlist`"
											v-model="model.licencecategory"
											:label="$t('driverlist.licencecategory')"
											:disabled="formDisabled"
											required
											toplabel
											maxlength="5"
										></pui-text-field>
									</v-col>
								</v-row>
							</pui-field-set>
							<pui-field-set :title="$t('driverlist.fieldset.vehicle')">
								<v-row dense>
									<!-- PLATENUMBER1 -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`PLATENUMBER1-driverlist`"
											v-model="model.plate"
											:label="$t('driverlist.plate1')"
											:disabled="formDisabled"
											toplabel
											required
											maxlength="10"
										></pui-text-field>
									</v-col>

									<!--VIN -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`VIN-driverlist`"
											v-model="model.vin"
											:label="$t('driverlist.vin')"
											:disabled="formDisabled"
											toplabel
											required
											maxlength="100"
										></pui-text-field>
									</v-col>
									<!-- VEHICLEBRAND -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`VEHICLEBRAND-driverlist`"
											v-model="model.brand"
											:label="$t('driverlist.vehiclebrand')"
											:disabled="formDisabled"
											toplabel
											required
											maxlength="100"
										></pui-text-field>
									</v-col>
									<!-- VEHICLECATEGORY -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											id="`VEHICLECATEGORY-driverlist`"
											:label="$t('driverlist.vehiclecategory')"
											toplabel
											required
											:disabled="formDisabled"
											singleItemAutoselect
											v-model="model"
											:modelFormMapping="{ value: 'category' }"
											:itemsToSelect="vehiclecategoryToSelect"
											itemValue="value"
											itemText="text"
											:items="codes1"
										></pui-select>
									</v-col>

									<!-- CARGO -->
									<v-col v-if="this.model.category === 'M3'" class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
										<pui-text-field
											:id="`CARGO-driverlist`"
											v-model="model.cargo"
											:label="$t('driverlist.cargo')"
											:disabled="formDisabled"
											toplabel
											required
											maxlength="100"
										></pui-text-field>
									</v-col>
									<v-col style="margin-top: 20px" class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-checkbox
											:id="`driverlist-secondvehicle`"
											:label="$t('driverlist.secondvehicle')"
											v-model="model.extravehicleind"
											:disabled="formDisabled"
											true-value="1"
											false-value="0"
										></pui-checkbox>
									</v-col>
								</v-row>
								<v-row dense>
									<!-- PLATENUMBER2 -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											v-if="this.model.extravehicleind == 1"
											:id="`PLATENUMBER2-driverlist`"
											v-model="model.plate2"
											:label="$t('driverlist.plate2')"
											:disabled="formDisabled"
											toplabel
											maxlength="10"
											required
										></pui-text-field>
									</v-col>
									<!--VIN2 -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											v-if="this.model.extravehicleind == 1"
											:id="`VIN2-driverlist`"
											v-model="model.vin2"
											:label="$t('driverlist.vin2')"
											:disabled="formDisabled"
											toplabel
											maxlength="100"
											required
										></pui-text-field>
									</v-col>
									<!-- VEHICLEBRAND2 -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											v-if="this.model.extravehicleind == 1"
											:id="`VEHICLEBRAND2-driverlist`"
											v-model="model.brand2"
											:label="$t('driverlist.vehiclebrand2')"
											:disabled="formDisabled"
											toplabel
											maxlength="100"
											required
										></pui-text-field>
									</v-col>
									<!-- VEHICLECATEGORY2 -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											v-if="this.model.extravehicleind == 1"
											id="`VEHICLECATEGORY2-driverlist`"
											:label="$t('driverlist.vehiclecategory2')"
											toplabel
											:disabled="formDisabled"
											singleItemAutoselect
											v-model="model"
											:modelFormMapping="{ value2: 'category2' }"
											:itemsToSelect="vehiclecategoryToSelect2"
											itemValue="value2"
											itemText="text"
											required
											:items="codes2"
										></pui-select>
									</v-col>

									<!-- CARGO2 -->
									<v-col
										v-if="this.model.category2 === 'M3' && this.model.extravehicleind == 1"
										class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
									>
										<pui-text-field
											:id="`CARGO2-driverlist`"
											v-model="model.cargo2"
											:label="$t('driverlist.cargo2')"
											:disabled="formDisabled"
											toplabel
											required
											maxlength="100"
										></pui-text-field>
									</v-col>
								</v-row>
							</pui-field-set>
						</v-col>
					</v-row>
				</v-tab-item>
			</v-tabs-items>

			<pui-form-footer>
				<pui-form-footer-btns
					:formDisabled="!havePermissions(['WRITE_DRIVERLIST'])"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'driverlistform',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'driverlist',
			modeldetail: true,
			tabmodel: 'driverlist',
			parentModelName: 'driverlistmenu',
			parentModelNameIFFGeneral: 'imofalformsgeneral',

			codes1: [
				{
					text: 'M1',
					value: 'M1'
				},
				{
					text: 'M2',
					value: 'M2'
				},
				{
					text: 'M3',
					value: 'M3'
				}
			],
			codes2: [
				{
					text: 'M1',
					value2: 'M1'
				},
				{
					text: 'M2',
					value2: 'M2'
				},
				{
					text: 'M3',
					value2: 'M3'
				}
			]
		};
	},
	mounted() {},
	computed: {
		gendercodeItemsToSelect() {
			return [{ gendercode: this.model.gendercode }];
		},
		typesIdDocItemsToSelect() {
			return [{ identdocid: this.model.identitydocid }];
		},
		typesIdDocItemsToSelect2() {
			return [{ identdocid: this.model.identitydocid2 }];
		},
		nationalityItemsToSelect() {
			return [{ countrycode: this.model.nationality }];
		},

		nationalityItemsToSelect2() {
			return [{ countrycode: this.model.nationality2 }];
		},
		issuingstateItemsToSelect() {
			return [{ countrycode: this.model.issuingstate }];
		},
		issuingstateItemsToSelect2() {
			return [{ countrycode: this.model.issuingstate2 }];
		},
		vehiclecategoryToSelect() {
			return [{ value: this.model.category }];
		},
		vehiclecategoryToSelect2() {
			return [{ value2: this.model.category2 }];
		}
	},
	watch: {
		'model.extravehicleind'() {
			if (this.model.extravehicleind === null) {
				this.model.extravehicleind = 0;
			}
		}
	},
	methods: {
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
		afterGetData() {
			// Do something after get data from server

			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
			if (parentModel) {
				this.model.driverlistid = parentModel.pk.imofalformid;
			}

			if (this.$router.currentRoute.path.startsWith('/portcalls/')) {
				const parentModelNameIFF = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelNameIFFGeneral);
				this.model.portcallid = parentModelNameIFF.parent.pk.portcallid;
				this.model.driverlistid = parentModelNameIFF.pk.imofalformid;
				console.log(this.model);
			}
		},
		getMaxDate() {
			const currentDate = new Date();
			const year = currentDate.getFullYear();
			const month = currentDate.getMonth() + 1; // Meses de 0 a 11
			const day = currentDate.getDate();

			// Formateamos la fecha en el formato YYYY-MM-DD
			return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
		}
	}
};
</script>
